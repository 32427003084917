import BlogPostPreviewGrid from "../components/blog-post-preview-grid";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../containers/layout";
import React, { useState, useEffect } from "react";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import { mapEdgesToNodes, filterOutDocsPublishedInTheFuture } from "../lib/helpers";
import useCollapse from 'react-collapsed'

import { responsiveTitle1 } from "../components/typography.module.css";
import '../styles/explore.css';
import fi from "date-fns/esm/locale/fi/index.js";

export const query = graphql`
  query ExplorePageQuery {
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
          tag {
            id
            title
          }
          categories {
            id
            title
          }
        }
      }
    }
    categories: allSanityCategory {
      edges {
        node {
          id
          title
        }
      }
    }
    tags: allSanityTag {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

const ExplorePage = (props) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  const { data, errors } = props;

  const postNodes = data && data.posts && mapEdgesToNodes(data.posts).filter(filterOutDocsPublishedInTheFuture);
  const tags = data && data.tags && mapEdgesToNodes(data.tags);
  const categories = data && data.categories && mapEdgesToNodes(data.categories);

  let [activeTagsAndCats, setActiveTagsAndCats] = useState([]);
  let articles = [...postNodes];
  let [activeArticles, setActiveArticles] = useState([...articles]);

  useEffect(() => {
    if (props.location.state && props.location.state.catId) {
      setActiveTagsAndCats([props.location.state.catId]);
      document.getElementById(props.location.state.catId).classList.toggle("active");
    }
  }, []);

  useEffect(() => {
    updateSelectedArticles()
  }, [activeTagsAndCats]);

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  // function handleToggleFilter(e, id, title, type) {
  //   const index = activeTagsAndCats.indexOf(id);
  //   if (index > -1) {
  //     activeTagsAndCats.splice(index, 1);
  //   } else {
  //     activeTagsAndCats.push(id);
  //   }
  //   e.target.classList.toggle("active");

  // if (type == "cat") {
  //   setActiveTagsAndCats([]);
  //   setActiveArticles([...articles])

  //   let filterButtons = Array.from(document.getElementsByClassName("tagOrCatButton"));
  //   for (let filter of filterButtons) {
  //     if (filter.classList.contains("active")) {
  //       filter.classList.toggle("active");
  //     }
  //   }
  // }

  // setActiveTagsAndCats([...activeTagsAndCats]);
  // updateSelectedArticles();
  // }

  function handleToggleFilter(e, id, title, type) {
    const index = activeTagsAndCats.indexOf(id);
    if (index > -1) {
      activeTagsAndCats.splice(index, 1);
      setActiveTagsAndCats([...activeTagsAndCats]);
      if (type == "cat") {
        setActiveArticles([...articles]);
      }
    } else {
      if (type == "cat") {
        setActiveTagsAndCats([id]);
        // activeTagsAndCats.push(id);
        // setActiveTagsAndCats([...activeTagsAndCats]);

        let filterButtons = Array.from(document.getElementsByClassName("tagOrCatButton"));
        for (let filter of filterButtons) {
          if (filter.classList.contains("active")) {
            filter.classList.toggle("active");
          }
        }
      } else {
        activeTagsAndCats.push(id);
        setActiveTagsAndCats([...activeTagsAndCats]);
      }
    }

    e.target.classList.toggle("active");
    // updateSelectedArticles();
  }

  function updateSelectedArticles() {
    activeArticles = articles.filter(article => {
      const getTagAndCatSortedList = arrayCombineAndSortHelper(article.tag, article.categories);
      const foundMatch = activeTagsAndCats.every(element => {
        return getTagAndCatSortedList.includes(element);
      });
      return foundMatch;
    });

    setActiveArticles([...activeArticles]);
  }

  function arrayCombineAndSortHelper(tagArray, catArray) {
    const combinedTagsAndCats = tagArray.concat(catArray);
    const justIds = [];

    combinedTagsAndCats.forEach(item => {
      justIds.push(item.id);
    });

    return justIds;
  }

  return (
    <Layout>
      <SEO title="Explore" />
      <Container>
        <div class="explore">
          <h1 className={responsiveTitle1}>Explore</h1>
          <p>Narrow your search by selecting tags. Deselect to alter your search. </p>
          <span className="catsAndTagsContainer">
            <div className="categoryContainer">
              <h3>Categories</h3>
              <ul>
                {categories &&
                  categories.map((category) => (
                    <li class="tagOrCatButton" id={category.id} onClick={(e) => handleToggleFilter(e, category.id, category.title, "cat")}>
                      {category.title}
                    </li>
                  ))}
              </ul>
            </div>
            <div className="tagContainer">
              <h3>Tags</h3>
              <span className="tagExpandButton" {...getToggleProps()}>
                {isExpanded ? '\u25B2' : '\u25BC'}
              </span>
              <ul {...getCollapseProps()}>
                {tags &&
                  tags.map((tag) => (
                    <li class="tagOrCatButton" id={tag.id} onClick={(e) => handleToggleFilter(e, tag.id, tag.title, "tag")}>
                      {tag.title}
                    </li>
                  ))}
              </ul>
            </div>
          </span>
        </div>
        {activeArticles && activeArticles.length > 0 && (
          <BlogPostPreviewGrid nodes={activeArticles} />
        )}
      </Container>
    </Layout>
  );
};

export default ExplorePage;
